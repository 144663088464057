<template>
    <div>
        <v-container v-if="ready">
            <v-card class="pa-4">

            <h1 class="mb-12">{{ $t('getcontacts.title') }}</h1>

            <!-- {{ contatti }} -->
            <v-data-table dense item-key="nome" show-expand single-expand :headers="headers" :items=" contatti"
                :items-per-page="50" class="elevation-1">


                <template v-slot:item.data="{ item }">
                    {{ to_human_data(item.data) }}
                </template>
                <template v-slot:item.stato="{ item }">
                    <v-chip v-if="item.stato == 0" small color="green">
                        <span class="white--text">
                            <strong>
                                {{ $t('getcontacts.message.new') }}
                            </strong>
                        </span>
                    </v-chip>
                    <!--  -->
                    <v-chip v-if="item.stato == 1" small color="yellow">
                        <span class="black--text">
                            <strong>
                                Letto
                            </strong>
                        </span>
                    </v-chip>
                    <!--  -->
                    <v-chip v-if="item.stato == 2" small color="green">
                        <span class="white--text">
                            <strong>
                                Letto
                            </strong>
                        </span>
                    </v-chip>
                    <!--  -->

                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td class="pt-8 pb-8" :colspan="headers.length">
                        <strong>
                            {{ $t('getcontacts.tableheader.message') }}
                        </strong>
                        <br>
                        {{ item.messaggio }}
                        <div class="text-right">
                            <v-btn color="secondary" x-small v-if="item.stato == 1"
                                @click="set_contact_state(item.id,0)">
                                <span class="primary--text">
                                    {{
                                    $t('getcontacts.button.mark_new') }}
                                </span>
                            </v-btn>
                            <v-btn color="secondary" dark x-small v-if="item.stato == 0"
                                @click="set_contact_state(item.id,1)">
                                <span class="primary--text">
                                    {{
                                    $t('getcontacts.button.mark_read') }}
                                </span>
                            </v-btn>
                            <v-btn x-small color="red" @click="open_dialog_delete_confirm(item.id)">
                                <span class="white--text">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </span>
                            </v-btn>
                        </div>
                    </td>
                </template>
            </v-data-table>

            <v-dialog v-model="delete_dialog" max-width="400">
                <v-card>
                    <v-card-title>
                        {{ $t('getcontacts.dialogdelete.title') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('getcontacts.dialogdelete.message') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn :disabled="deleting" outlined @click="delete_dialog=false">{{
                        $t('getcontacts.dialogdelete.btn.close') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="deleting" outlined color="red" @click="delete_contact()">{{
                        $t('getcontacts.dialogdelete.btn.confirm') }}</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>

            <BackToAdminPanel />
            </v-card>
        </v-container>
    </div>
</template>

<script>
import BackToAdminPanel from '@/components/BackToAdminPanel.vue'

export default {
    props: {},
    components: {
        BackToAdminPanel
    },
    data: () => ({
        ready: false,
        id_to_delete: null,
        delete_dialog: false,
        deleting: false
        // contatti: []
    }),
    methods: {
        get_contacts() {
            this.$store.dispatch("contacts/act_get_contatti").then(()=> {
                this.ready = true
            })
        },
        set_contact_state(id, state) {
            let id_state = {}
            id_state.id = id
            id_state.state = state
            this.$store.dispatch("contacts/act_change_contact_state", id_state)
        },
        to_human_data(data) {
            if (data) {
                return data.toDate().toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })
            } else {
                return null
            }
        },
        open_dialog_delete_confirm(id) {
            this.id_to_delete = id
            this.delete_dialog = true
        },
        delete_contact(id) {
            this.deleting = true
            this.$store.dispatch("contacts/act_delete_contact", this.id_to_delete).then(() => {
                this.delete_dialog = false
                this.deleting = false
            })
        }
    },
    mounted() {
        this.get_contacts()
    },
    created() { },
    computed: {
        contatti() {
            return this.$store.getters["contacts/get_contatti"]
        },
        headers() {
            return [
                {
                    text: this.$t('getcontacts.tableheader.data'),
                    align: 'start',
                    sortable: true,
                    value: 'data',
                },
                {
                    text: this.$t('getcontacts.tableheader.type'),
                    align: 'start',
                    sortable: true,
                    value: 'tipo',
                },
                {
                    text: this.$t('getcontacts.tableheader.name'),
                    align: 'start',
                    sortable: true,
                    value: 'nome',
                },
                {
                    text: this.$t('getcontacts.tableheader.email'),
                    align: 'start',
                    sortable: true,
                    value: 'email',
                },
                {
                    text: this.$t('getcontacts.tableheader.status'),
                    align: 'start',
                    sortable: true,
                    value: 'stato',
                },
                { text: '', value: 'data-table-expand' },
            ]
        },

    },


    watch: {}

}
</script>

<style>

</style>